<template>
  <div>
    <!-- 模块1 - 大图 -->
    <div class="dis-flex a-i-center j-c-center h-527 contactus-model1">
      <h3 class="c-fefefe fs-48 fw-b">{{ $g('contactUsModel1.title') }}</h3>
    </div>
    <!-- 模块2 -->
    <div class="pt-70 pb-90 bb-f6f6f6-2">
      <div class="w-1040 mlr-auto">
        <h3 class="c-3 fs-18">{{ $g('contactUsModel2.title') }}</h3>
        <p><i class="dis-ib h-6 w-35 bg-main mt-10"></i></p>
        <p class="mt-20 c-9">{{ $g('contactUsModel2.content') }}</p>
        <div class="mt-60 dis-flex">
          <img
            width="380"
            height="418"
            :src="$g('contactUsModel2.image1')"
            alt=""
          />
          <ul class="flex-1 ml-50">
            <li class="mb-10 c-6 fs-18 fw-b">
              {{ $g('contactUsModel3.title') }}
            </li>
            <li
              v-for="(item, index) in formData"
              :key="index"
              class="mt-15"
            >
              <input
                v-if="item.type == 'text'"
                class="h-50 w-p100 bg-f4f4f4 bd-n plr-20 input-main br-0"
                v-model="cdata[item.key]"
                type="text"
                :placeholder="item.placeholder"
              />
              <textarea
                v-else
                v-model="cdata[item.key]"
                class="pd-20 h-160 w-p100 bg-f4f4f4 bd-n resize-n input-main br-0"
                :placeholder="item.placeholder"
              ></textarea>
            </li>
            <li class="mt-20">
              <span class="cp txt-c dis-ib w-200 h-50 lh-50 c-f bg-main" @click="submit">{{
                $g('contactUsModel3.ext5')
              }}</span>
              <span v-if="showTip" class="c-3 c-main ml-30">{{$g('contactUsModel3.content')}}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 模块3 -->
    <div class="pt-40 pb-90 bb-f6f6f6-2">
      <div class="w-1040 mlr-auto">
        <h3 class="c-3 fs-18">{{ $g('contactUsModel4.title') }}</h3>
        <p><i class="dis-ib h-6 w-35 bg-main mt-10"></i></p>
        <ul class="mt-40 dis-flex j-c-between">
          <li class="mr-80">
            <span
              :class="[
                'dis-ib lh-30 mr-25 h-30 plr-20 fs-16 cp',
                cityindex == index ? 'bg-main c-f' : 'bg-e4e4e4 c-a1a1a1',
              ]"
              @click="cityindex = index"
              v-for="(item, index) in $t.contactUsModel5"
              :key="index"
              >{{ item.title }}</span
            >
            <p class="h-40 dis-flex a-i-center mt-20">
              <span class="w-20 txt-c mr-15">
                <img
                class="va-m"
                :src="$g('contactUsModel51.image1')"
                width="14"
                height="16"
                alt=""
              /></span>
              <span>{{ $t.contactUsModel5 ? $t.contactUsModel5[cityindex].content : '' }}</span>
            </p>
            <p class="h-40 dis-flex a-i-center mt-20">
              <span class="w-20 txt-c mr-15">
                <img
                class="va-m"
                :src="$g('contactUsModel51.image2')"
                width="18"
                height="13"
                alt=""
              /></span>
              <span>{{ $t.contactUsModel5 ? $t.contactUsModel5[cityindex].ext5 : '' }}</span>
            </p>
          </li>
          <li>
            <img
              height="219"
              class="object-c"
              :src="$t.contactUsModel5 ? $t.contactUsModel5[cityindex].image1 : ''"
              alt=""
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  name: "Contactus",
  data() {
    return {
      showTip:false,
      cdata:{name:"",email:"",title:"",content:"",source:""},
      cityindex: 0,
    };
  },
  computed: {
    formData(){
      let me=this;
      return [
            { key: "name", placeholder: me.$t.contactUsModel3 ? me.$t.contactUsModel3.ext1 : '', type: "text" },
            { key: "email", placeholder: me.$t.contactUsModel3 ? me.$t.contactUsModel3.ext2 : '', type: "text" },
            { key: "title", placeholder: me.$t.contactUsModel3 ? me.$t.contactUsModel3.ext3 : '', type: "text" },
            {
              key: "content",
              placeholder: me.$t.contactUsModel3 ? me.$t.contactUsModel3.ext4 : '',
              type: "textarea",
            },
          ]
    },
  },
  methods: {
    async submit(){
      let me = this;
      try{
        if ( !me.cdata.name ||!me.cdata.email ){
          return;
        }
        me.cdata.source = "pc-"+me.$app.curLan.name;
        await axios.post("/data/gwfeedback/add",me.cdata);
        me.showTip = true;
        setTimeout(function(){
          me.cdata = {name:"",email:"",title:"",content:"",source:""}
          me.showTip = false;
        },3000)
      }catch(e){
        console.log(e)
      }
    }
  },
};
</script>
<style>
.contactus-model1 {
  background: url(/static/images/contactus/model1-bg.jpg) no-repeat center;
  background-size: cover;
}
</style>